@use "sass:map";
@use "../vars" as vars;

section#index-hero {
    img {
        width: 100%;
        height: auto;
    }
}

#index-policy-stats {
    margin-top: 3rem;

    > h2 {
        text-align: center;
    }

    .stats-container {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;    
    }
}

#cht-policy-pie {
    position: relative;
    flex: 7;

    h3 {
        text-align: center;
    }

    & div.cht-container {
        position: relative;
        min-width: 15vw;
        width: 15vw;
        margin: 0 auto;
    }

    @media screen and (max-width: 599px) {
        & {
            flex: 100%;
        }

        & div.cht-container {
            min-width: 60vw;
        }
    }
}

#cht-policy-detail {
    flex: 3;
    // padding: 1.5rem 3rem;

    &.hide {
        display: none;
    }

    &.animate-show {
        transition: all;
        flex: 3;
        max-width: 100vw;
    }

    div.detail {
        display: flex;
        position: relative;
        margin-bottom: 1rem;

        .icon {
            flex: 1 0 1vw;
            margin: 0 1rem;

            .top {
                transition: opacity 0.6s ease-in-out;
            }

            .fade-out {
                transition: opacity 0.6s ease-in-out;
            }

            .transparent {
                opacity: 0;
            }
        }
    
        .cht-container {
            max-height: 30vh;
            position: relative;
            margin: 0 auto;
        }
    }
    
    #loader-policy-stats {
        display: block;
        margin: 0 auto;

        &.hide {
            display: none;
        }
    }

    div.preview-policies {
        h5 {
            color: map.get(vars.$colors, "upri-green");
            padding: 0;
            margin-bottom: 1rem;
        }

        &.hide {
            display: none;
        }
    }

    & table.tbl-themes {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;

        & tr:nth-child(2n) {
            background: transparent;
        }

        // Table borders
        & td {
            border: 1px solid black;
            border-style: none solid solid none;
            font-size: 0.85rem;
            padding: 0.5rem;
            text-align: center;
        }

        & tr:first-child td {
            border-top: 1px solid black;
        }

        & tr td:first-child {
            border-left: 1px solid black;
        }

        $cl: (
            "first": "top",
            "last": "bottom"
        );
        $cl2: (
            "first": "left",
            "last": "right"
        );

        @each $td_pos_x, $border_pos_x in $cl {
            @each $td_pos_y, $border_pos_y in $cl2 {
                & tr:#{$td_pos_x}-child td:#{$td_pos_y}-child {
                    border-#{$border_pos_x}-#{$border_pos_y}-radius: 15px;
                }
            }
        }
    }

    @media screen and (max-width: 599px) {
        & {
            flex: 100%;
        }
    }
}

#index-policy-feature {
    margin-top: 3rem;

    > h2 {
        text-align: center;
    }

    article.card {
        height: 70vh;
        padding: 0;

        h3 {
            color: map.get(vars.$colors, "upri-green");
            padding: 1rem 1.5rem 0 1.5rem;
        }

        div.policy-category-tags {
            padding: 1rem 1.5rem 0 1.5rem;
        }

        .content {
            font-size: 0.925rem;
            padding: 1rem 1.5rem 0 1.5rem;

            @media (prefers-color-scheme: dark) {
                & {
                    color: map.get(vars.$colors, "dark-gray");
                }
            }
        }

        a.footer-link {
            position: absolute;
            bottom: 0;
            width: 100%;
            min-height: 32px;
            background-color: map.get(vars.$colors, "upri-green");
            color: map.get(vars.$colors, "white");

            & span.text {
                position: absolute;
                width: 100%;
                font-size: 0.9rem;
                font-family: vars.$font-fam-poppins;
                font-weight: bold;
                text-align: center;
                top: 50%;
                transform: translateY(-50%);
            }
            
            &:hover {
                color: map.get(vars.$colors, "yellow");
            }
        }
    }
}