@use "sass:map";
@use "../vars" as vars;

@media print {
    article#policy-detail {
        .head {
            width: 100%;
        }

        .content {
            .meta {
                margin-top: 0.5cm;
                width: 100%;
                order: 0;

                .item {
                    & > .policy-category-tags {
                        span.label {
                            display: block;
                            font-size: 1.5rem;
                            color: map.get(vars.$colors, "maroon");
                            background-color: transparent;
                        }
                    }

                    &.full-text {
                        display: block;

                        :nth-child(2) {
                            font-family: vars.$font-fam-dm-mono;
                        }
                    }
                }

                .links {
                    display: none;
                }
            }

            .text {
                margin-top: 0cm;
                width: 100%;
                order: 1;
            }
        }
    }
}