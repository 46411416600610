@use "sass:map";
@use "../vars" as vars;

article#policy-detail {
    h1 {
        padding: 0;
        color: map.get(vars.$colors, "maroon");
    }

    .policy-detail-serial {
        display: flex;
        align-items: center;

        span.serial {
            text-transform: uppercase;
            font-family: vars.$font-fam-dm-mono;
            color: map.get(vars.$colors, "dark-gray");
            cursor: pointer;

            & + i.permalink-icon {
                margin-left: 0.5rem;
                padding: 0.125rem;
                background-color: map.get(vars.$colors, "light-green");
                font-size: 1.25rem;
                border-radius: 20rem;
                display: none;
            }

            &:hover + i.permalink-icon {
                display: inline;
            }
        }
    }

    .head {
        margin-left: 0;

        * {
            padding-left: 0;
        }
    }

    .content {
        .meta {
            order: 1;

            .item {
                & > span:nth-child(2):not(.policy-category-tags) {
                    display: block;
                    font-size: 1.5rem;
                    color: map.get(vars.$colors, "maroon");
                }
            }

            .links {
                display: flex;
                gap: 0.25rem;
            }

            @media (max-width: vars.$max-mobile-width) {
                order: 0;
            }
        }

        .text {
            margin-top: 1vh;
            order: 0;

            @media (max-width: vars.$max-mobile-width) {
                order: 1;
            }
        }
    }
}

@media (prefers-color-scheme: dark) {
    article#policy-detail {
        h1, .content .meta .item > span:nth-child(2):not(.policy-category-tags) {
            color: map.get(vars.$colors, "light-green");
        }

        .policy-detail-serial {
            color: map.get(vars.$colors, "sky-blue");
        }
    }
}