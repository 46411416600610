@use "sass:map";
@use "../vars" as vars;

form#add-policy {
    [required]:after {
        content: "*";
        color: map.get(vars.$colors, "maroon");
    }
}

#add-policy-preview {
    border: 1px solid black;
}