@use "sass:map";
@use "../vars" as vars;

form#form-feedback {
    [required]::after {
        content: "*";
        color: map.get(vars.$colors, "maroon");
    }

    input:focus, textarea:focus, select:focus {
        border-color: map.get(vars.$colors, "green");
    }

    input.invalid, textarea.invalid, select.invalid {
        border-color: map.get(vars.$colors, "light-red");
    }

    button[type="submit"] {
        background-color: map.get(vars.$colors, "green");

        .right-icon {
            margin-left: 0.5rem;
        }

        &:disabled {
            background-color: map.get(vars.$colors, "dark-gray");

            .right-icon {
                animation: submit-move 0.7s ease-out infinite alternate;
            }
        }
    }

    textarea {
        resize: vertical;
        min-height: 5rem;
    }
}

@keyframes submit-move {
    0% {
        transform: translate(0%, 0%);
    }

    100% {
        transform: translate(100%, 0%);
    }
}