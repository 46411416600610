@use "sass:map";
@use '../vars' as vars;

#search-results {
    margin-top: 2rem;
    order: 0;

    .card {
        border: 0;
        border-radius: 0;
        padding: 0;
        margin-bottom: 2rem;
        background-color: transparent;

        h3 {
            font-family: vars.$font-fam-poppins;
            font-weight: bold;
            padding: 0;
            margin-bottom: 0.5rem;

            & a {
                color: map.get(vars.$colors, "upri-green");
            }
        }

        p {
            padding: 0;
            font-size: 0.9rem;
        }

        @media (max-width: 600px) {
            & {
                padding: vars.$card-padding;
                border-radius: 1rem;
                border: 1px solid map.get(vars.$colors, "light-gray");
                background-color: white;
            }
        }
    }

    .page-sep {
        position: relative;
        width: 100%;
        height: 1.5rem;
        margin-bottom: 1rem;

        &::before {
            content: "";
            position: absolute;
            background-color: map.get(vars.$colors, "maroon");
            height: 1px;
            left: 1.5rem;
            top: 0.75rem;
            right: 0;
        }

        span.index {
            font-weight: bold;
        }
    }

    & + .search-page-more {
        width: 75%;
        margin: 0 auto;
        column-gap: 0.25rem;

        @media (max-width: 600px) {
            & {
                width: 100%;
            }
        }
    }

    @media (max-width: vars.$max-mobile-width) {
        order: 1;
    }
}

#search-results-margin {
    order: 1;

    #sbook-dl.card {
        & h3 {
            color: map.get(vars.$colors, "upri-green");
            padding: 0;
            margin-bottom: 1rem;
        }

        & p {
            margin: 0;
            padding: 0;
        }

        & .download-btn {
            margin-top: 1rem;

            button.view-more {
                .hide {
                    display: none;
                }
            }

            #loader-generate-sbook {
                display: block;
                margin: 0 auto;
            
                &.hide {
                    display: none;
                }
            }
        }
    }

    @media (max-width: vars.$max-mobile-width) {
        order: 0;
    }
}

@media (prefers-color-scheme: dark) {
    .card {
        @media (max-width: 600px) {
            & {
                color: black;
            }
        }
    }
}